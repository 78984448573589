import React from "react"
import {Snackbar as Snack, IconButton, SnackbarContent} from "@material-ui/core"
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
}

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: theme.palette.success.dark,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

function SnackbarContentWrapper(props) {
  const classes = useStyles1()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]
  
  return (
    <SnackbarContent
      className={classes[variant] + " " + className}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classes.icon + " " + classes.iconVariant} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'success']).isRequired,
}

function Snackbar(props) {
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    props.handleClose(props.id)
  }
  
  return (
    <Snack
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={props.open}
      autoHideDuration={props.duration || 6000}
      onClose={handleClose}
      style={{marginTop: "5px", left: "24px", bottom: "24px"}}
    >
      <SnackbarContentWrapper
        onClose={handleClose}
        variant={props.variant}
        message={props.message}
      />
    </Snack>
  )
  
}

export default Snackbar