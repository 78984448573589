import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import DataTable from "../components/DataTable";
import SearchBox from "../components/SearchBox";
import useApi from "../hooks/useApi";
import useSnackbar from "../hooks/useSnackbar";
import FicheUtilisateur from "./FicheUtilisateur";

function Utilisateur() {
  const [users, setUsers] = useState([]);
  const [cpyUsers, setCpyUsers] = useState([]);
  const [userFiche, setUserFiche] = useState([]);

  const { usersApi } = useApi();

  const updateUsers = async () => {
    const tmpUsers = await usersApi.getAll();
    if (!tmpUsers.error) {
      setUsers(tmpUsers);
      setCpyUsers(tmpUsers);
    } else {
      setUsers([]);
      setCpyUsers([]);
    }
  };

  useEffect(() => {
    (async () => {
      await updateUsers();
    })();
  }, []);

  const handleClick = async (event, indexes) => {
    // const user = users.filter((item) => item.user_id === indexes.dataIndex)[0]
    const user = users[indexes.dataIndex];
    setUserFiche(user);
    setFicheOpen(true);
    // open the details page with editing functionality
  };

  const { addSuccessSnackbar, addErrorSnackbar } = useSnackbar();

  const handleSave = async (
    userId,
    username,
    isAdmin,
    isActif,
    password,
    changePassword
  ) => {
    let result;
    if (userId) {
      result = await usersApi.put(userId, {
        username: username ? username : userFiche.username,
        isAdmin: Boolean(isAdmin),
        isActif: Boolean(isActif),
        changePassword: Boolean(changePassword),
        password,
      });
    } else {
      result = await usersApi.post({
        username: username ? username : userFiche.username,
        isAdmin: Boolean(isAdmin),
        isActif: Boolean(isActif),
        password,
      });
    }
    handleCloseFiche();
    if (!result.errno && !result.error) {
      addSuccessSnackbar("Utilisateur enregistré");
    } else {
      addErrorSnackbar("Un problème est survenu, merci d'essayer à nouveau");
    }
    updateUsers();
  };

  const headers = [
    {
      name: "username",
      label: "Nom d'utilisateur",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "isAdmin",
      label: "Administrateur",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
        customBodyRender: (value) => {
          return value ? "Oui" : "Non";
        },
      },
    },
    {
      name: "isActif",
      label: "Actif",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
        customBodyRender: (value) => {
          return value ? "Oui" : "Non";
        },
      },
    },
  ];

  const [userSelected, setUserSelected] = useState(false);

  useEffect(() => {
    if (userSelected) {
      setUsers(
        cpyUsers.filter((item) => item.user_id === userSelected.user_id)
      );
    } else {
      setUsers(cpyUsers);
    }
  }, [userSelected, cpyUsers]);

  const getString = (obj) => {
    return obj ? `${obj.username}` : "";
  };

  const [ficheOpen, setFicheOpen] = useState(false);

  const handleCloseFiche = () => setFicheOpen(false);

  return (
    <div>
      <h1>Utilisateurs</h1>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <SearchBox
          nomargin
          data={cpyUsers}
          label={"Utilisateur"}
          value={userSelected}
          setValue={setUserSelected}
          getString={getString}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setUserFiche({});
            setFicheOpen(true);
          }}
        >
          Nouveau utilisateur
        </Button>
      </div>
      <DataTable
        title={"Liste des utilisateurs"}
        columns={headers}
        data={users}
        handleRowClick={handleClick}
      />
      <FicheUtilisateur
        user={userFiche}
        open={ficheOpen}
        handleClose={handleCloseFiche}
        handleSave={handleSave}
      />
    </div>
  );
}

export default Utilisateur;
