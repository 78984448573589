import React from 'react'
import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import {AppBar, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import './Menu.css'
import useStyles from "./MenuStyles"
import MenuTopbar from "./MenuTopbar"
import {
  Link,
} from "react-router-dom"

export default function Menu(props) {
  const menuItems = props.menuItems
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  let initMenu = menuItems.accueil
  const pathname = window.location.pathname
  Object.values(menuItems).map((item) => {
    if ((item.link) === pathname) {
      initMenu = item
    }
    return true
  })
  const [menuItem, setMenuItem] = React.useState(initMenu)
  
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  
  const handleDrawerClose = () => {
    setOpen(false)
  }
  
  const handleClick = (item) => {
    setMenuItem(item)
  }
  
  return (
    <div className={classes.root} style={{minWidth: "300px"}}>
      <CssBaseline/>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <MenuTopbar icon={(
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon/>
          </IconButton>
        )} />
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
          </IconButton>
        </div>
        <Divider/>
        <List>
          {Object.values(menuItems).map((item) => (
            <ListItem
              button
              key={item.link}
              className={item === menuItem ? 'active' : ''}
              onClick={() => handleClick(item)}
              component={Link}
              to={item.link}>
              <ListItemIcon>{< item.icon fontSize='default'/>}</ListItemIcon>
              <ListItemText primary={item.text}/>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar}/>
        {props.children}
      </main>
    </div>
  )
}