import React, { useState } from "react";
import useApi from "./hooks/useApi";

const UserContext = React.createContext();

function UserContextProvider(props) {
  // const [isLoggedIn, setIsLoggedIn] = useState(getUser().user_id);
  const [ready, setReady] = useState(false);
  const [authState, setAuthState] = useState({
    expiresAt: null,
    user: null,
  });
  const { securityApi } = useApi();

  function login(user, expiresAt) {
    // localStorage.setItem("user_id", user.user_id);
    // localStorage.setItem("username", user.username);
    // localStorage.setItem("isAdmin", user.isAdmin);
    // localStorage.setItem("expiresAt", expiresAt);
    setAuthState({ user, expiresAt });
    // setIsLoggedIn(true);
  }

  function logout() {
    // localStorage.removeItem("user_id");
    // localStorage.removeItem("username");
    // localStorage.removeItem("isAdmin");
    // localStorage.removeItem("expiresAt");
    setAuthState({ user: null, expiresAt: null });
    // setIsLoggedIn(false);
  }

  function getUser() {
    // const user_id = localStorage.getItem("user_id");
    // const username = localStorage.getItem("username");
    // const isAdmin = localStorage.getItem("isAdmin");
    // return {
    //   user_id,
    //   username,
    //   isAdmin,
    // };
    return authState.user ?? {};
  }

  function isLoggedIn() {
    if (!ready) {
      return securityApi
        .me()
        .then((data) => {
          if (data?.user && data?.expiresAt) {
            setAuthState({
              user: data.user,
              expiresAt: new Date(data.expiresAt),
            });
            return new Date().getTime() / 1000 < new Date(data.expiresAt);
          }
          return false;
        })
        .catch(() => false)
        .finally(() => setReady(true));
    } else {
      if (!authState.expiresAt || !authState.user) {
        return false;
      }
      return new Date().getTime() / 1000 < authState.expiresAt;
    }
  }

  return (
    <UserContext.Provider value={{ isLoggedIn, login, logout, getUser }}>
      {props.children}
    </UserContext.Provider>
  );
}

export { UserContextProvider, UserContext };
