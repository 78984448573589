import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {UserContextProvider} from './UserContext'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles'

const theme = {
  palette: {
    primary: {main: '#374873', contrastText: '#FFFFFF'},
    secondary: {main: '#FFFFFF', contrastText: '#374873'},
  },
}

ReactDOM.render(
  <MuiThemeProvider theme={createMuiTheme(theme)}>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </MuiThemeProvider>
, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
