import React, { useContext } from "react";
import "./App.css";
import Analyse from "./Analyse";
import Client from "./Client";
import SnackbarsDisplay from "./components/SnackbarsDisplay";
import Connexion from "./Connexion";
import Utilisateur from "./Utilisateur";
import Menu from "./components/Menu/Menu";
import { UserContext } from "./UserContext";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import PeopleIcon from "@material-ui/icons/People";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import SettingsIcon from "@material-ui/icons/Settings";

function App() {
  const { isLoggedIn, getUser } = useContext(UserContext);

  const menuItems = {
    clients: { text: "Clients", icon: PeopleIcon, link: "/clients" },
    analyses: { text: "Analyses", icon: LocalDrinkIcon, link: "/analyses" },
  };

  if (getUser().isAdmin === 1) {
    Object.assign(menuItems, {
      utilisateurs: {
        text: "Utilisateurs",
        icon: SettingsIcon,
        link: "/utilisateurs",
      },
    });
  }

  const userLoggedIn = isLoggedIn();

  return (
    <div className="App">
      <Router>
        <Route exact path="/">
          {!userLoggedIn ? (
            <Redirect to={"/connexion"} />
          ) : (
            <Redirect to={"/clients"} />
          )}
        </Route>
        <Route exact path="/connexion">
          {!userLoggedIn ? <Connexion /> : <Redirect to={"/clients"} />}
        </Route>
        <Route path="/clients">
          {!userLoggedIn ? (
            <Redirect to={"/connexion"} />
          ) : (
            <Menu menuItems={menuItems}>
              <Client />
            </Menu>
          )}
        </Route>
        <Route path="/analyses">
          {!userLoggedIn ? (
            <Redirect to={"/connexion"} />
          ) : (
            <Menu menuItems={menuItems}>
              <Analyse />
            </Menu>
          )}
        </Route>
        <Route path="/utilisateurs">
          {!userLoggedIn ? (
            <Redirect to={"/connexion"} />
          ) : getUser().isAdmin !== 1 ? (
            <Redirect to={"/clients"} />
          ) : (
            <Menu menuItems={menuItems}>
              <Utilisateur />
            </Menu>
          )}
        </Route>
      </Router>
      <SnackbarsDisplay />
    </div>
  );
}

export default App;
