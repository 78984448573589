import Button from "@material-ui/core/Button";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "../components/DataTable";
import SearchBox from "../components/SearchBox";
import useApi from "../hooks/useApi";
import EditClient from "./EditClient";

function Client(props) {
  const [clients, setClients] = useState([]);
  const [cpyClients, setCpyClients] = useState([]);

  const { clientsApi } = useApi();

  useEffect(() => {
    (async () => {
      const tmpClients = await clientsApi.getAll();
      if (!tmpClients.error) {
        setClients(tmpClients);
        setCpyClients(tmpClients);
      } else {
        setClients([]);
        setCpyClients([]);
      }
    })();
  }, []);

  const handleClick = (event, indexes) => {
    // filter : clients.filter((item) => item.client_id === id)
    // open the details page with editing functionality
    setClientFiche(clients[indexes.dataIndex]);
    setOpen(true);
  };

  const headers = [
    {
      name: "fullname",
      label: "Nom complet",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "address",
      label: "Adresse",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "phone",
      label: "Téléphone",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "countAnalyses",
      label: "Nombre d'analyses",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "piscineType",
      label: "Type de piscine",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "piscineVolume",
      label: "Volume piscine (m3)",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "filtreType",
      label: "Type de filtre",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "filtreVolume",
      label: "Volume filtre (m3/h)",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "pompePuissance",
      label: "Puissance pompe",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "pompeMarque",
      label: "Marque de la pompe",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "pompeDebit",
      label: "Débit pompe (m3/h)",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "traitementEau",
      label: "Traitement eau",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "traitementDateRevision",
      label: "Date révision traitement d'eau",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
        customBodyRender: (value) => {
          return value ? moment(value).format("D MMMM YYYY") : "";
        },
      },
    },
    {
      name: "pH",
      label: "pH",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "pHMarque",
      label: "Marque pH",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "desinfectant",
      label: "Désinfectant",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "desinfectantMarque",
      label: "Marque désinfectant",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "tauxSelRequis",
      label: "Taux de sel requis",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "chauffage",
      label: "Chauffage",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
        customBodyRender: (value) => {
          return value ? "Oui" : "Non";
        },
      },
    },
    {
      name: "couverte",
      label: "Couverte",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
        customBodyRender: (value) => {
          return value ? "Oui" : "Non";
        },
      },
    },
  ];

  const [clientSelected, setClientSelected] = useState(false);

  useEffect(() => {
    if (clientSelected) {
      setClients(
        cpyClients.filter((item) => item.client_id === clientSelected.client_id)
      );
    } else {
      setClients(cpyClients);
    }
  }, [clientSelected, cpyClients]);

  useEffect(() => {
    setClientSelected(props.client ? props.client : false);
  }, []);

  const getString = (obj) => {
    return obj ? `${obj.fullname}` : "";
  };

  const [clientFiche, setClientFiche] = useState({});
  const [open, setOpen] = useState(false);

  return (
    <div style={{ maxWidth: "100%" }}>
      <h1>Clients</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <SearchBox
          nomargin
          data={cpyClients}
          label={"Client"}
          value={clientSelected}
          setValue={setClientSelected}
          getString={getString}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setClientFiche({});
            setOpen(true);
          }}
        >
          Nouveau client
        </Button>
      </div>
      <DataTable
        title={"Liste des clients"}
        columns={headers}
        data={clients}
        dataKey={"client_id"}
        handleRowClick={handleClick}
      />
      <EditClient
        open={open}
        close={() => setOpen(false)}
        triggerUpdate={async () => {
          const updateClients = await clientsApi.getAll();
          if (!updateClients.error) {
            setClients(updateClients);
            setCpyClients(updateClients);
          } else {
            setClients([]);
            setCpyClients([]);
          }
        }}
        client={clientFiche}
        clients={clients}
      />
    </div>
  );
}

export default Client;
