import React, {useState} from 'react'
import { TextField }  from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

function SearchBox(props) {
  
  const [input, setInput] = useState("")
  
  return (
    <Autocomplete
      id="search-box"
      options={props.data}
      getOptionLabel={option => props.getString(option)}
      style={{ width: 450, margin: props.nomargin ? "20px" : "20px auto" }}
      inputValue={input}
      onInputChange={(event, newInput) => {
        setInput(newInput !== "" ? newInput : props.getString(props.value))
      }}
      value={props.value}
      onChange={(event, newValue) => {
        props.setValue(newValue)
        setInput(props.getString(newValue))
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={ props.label }
          variant="outlined"
          fullWidth />
      )}
      disabled={props.disabled}
    />
  )
}

export default SearchBox