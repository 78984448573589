import { Checkbox, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useEffect, useState } from "react";
import Modal from "../components/Modal";

function FicheUtilisateur(props) {
  const { handleClose, open, user } = props;

  const [username, setUsername] = useState("");
  const [isAdmin, setIsAdmin] = useState(user.isAdmin);
  const [isActif, setIsActif] = useState(user.isActif);
  const [changePassword, setChangePassword] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    setUsername(user.username);
    setIsAdmin(user.isAdmin);
    setIsActif(user.isActif);
  }, [user]);

  const modalActions = (
    <Button
      onClick={() => {
        props.handleSave(
          user.user_id,
          username,
          isAdmin !== undefined ? isAdmin : user.isAdmin,
          isActif !== undefined ? isActif : user.isActif,
          password,
          changePassword
        );
      }}
      variant="contained"
      color="primary"
    >
      Enregistrer
    </Button>
  );

  return (
    <Modal
      title={"Fiche utilisateur - " + (username ? username : user.username)}
      open={open}
      id="fiche-user"
      handleClose={() => handleClose()}
      actions={modalActions}
    >
      <TextField
        value={username ? username : user.username}
        label="Nom d'utilisateur"
        onChange={(event) => {
          setUsername(event.target.value);
        }}
      />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            value={isAdmin}
            checked={
              isAdmin !== undefined ? Boolean(isAdmin) : Boolean(user.isAdmin)
            }
            onChange={(event) => {
              setIsAdmin(event.target.checked);
            }}
          />
        }
        label="Administrateur"
      />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            value={isActif}
            checked={
              isActif !== undefined ? Boolean(isActif) : Boolean(user.isActif)
            }
            onChange={(event) => {
              setIsActif(event.target.checked);
            }}
          />
        }
        label="Actif"
      />
      {user.user_id && (
        <>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={changePassword}
                checked={changePassword}
                onChange={(event) => {
                  setChangePassword(event.target.checked);
                }}
              />
            }
            label="Changer le mot de passe"
          />
        </>
      )}
      {(!user.user_id || changePassword) && (
        <>
          <br />
          <TextField
            value={password}
            label="Mot de passe"
            type="password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </>
      )}
    </Modal>
  );
}

export default FicheUtilisateur;
