import React, {useEffect} from "react"
import Snackbar from "./Snackbar"

function SnackbarsDisplay(props) {
  const [toasts, setToasts] = React.useState([])
  
  useEffect(() => {
    document.addEventListener('show-toast-message', function (e) {
      const config = {
        id: Date.now(),
        message: e.detail.message,
        variant: e.detail.variant,
        duration: e.detail.duration || 6000,
        // callback: e.detail.callback,
        open: true
      }
      
      addToast(config)
    })
  }, [])
  
  const addToast = toast_cnf => {
    setToasts(old_toasts => {
      return [toast_cnf, ...old_toasts]
    })
  }
  
  const removeToast = id => {
    setToasts(old_toasts => {
      // eslint-disable-next-line eqeqeq
      return old_toasts.filter(t => t.id != id)
    })
  }
  
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      zIndex: "1000000",
      bottom: "0",
      left: "0",
    }}>
      {toasts.map(toast_params =>
        <Snackbar
          id={toast_params.id}
          key={toast_params.id}
          handleClose={id => removeToast(id)}
          {...toast_params}
        />
      )}
    </div>
  )
}

export default SnackbarsDisplay