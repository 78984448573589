import React, { useContext } from "react";
import { Button, Fab, Grid, Toolbar, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { UserContext } from "../../UserContext";
import useApi from "../../hooks/useApi";

function MenuTopbar(props) {
  const { isLoggedIn, logout, getUser } = useContext(UserContext);

  const { securityApi } = useApi();

  const handleLogout = () => {
    securityApi.logout().then((data) => {
      if (!data.error) {
        logout();
      }
    });
  };

  return (
    <div style={{ minWidth: "300px" }}>
      <Toolbar>
        {props.icon}
        <Typography variant="h6" noWrap>
          Cristal'In - Gestion des analyses
        </Typography>
        <div style={{ marginLeft: "auto", display: "inline-block" }}>
          {/*Right align items*/}
          <div>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography noWrap>
                  Connecté en tant que : {getUser().username}
                </Typography>
              </Grid>
              {isLoggedIn ? (
                <Grid item>
                  <Fab size={"small"} onClick={handleLogout}>
                    <ExitToAppIcon />
                  </Fab>
                </Grid>
              ) : (
                <Grid item>
                  <Link to={"/connexion"}>
                    <Button variant={"contained"}>Connexion</Button>
                  </Link>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </Toolbar>
    </div>
  );
}

export default MenuTopbar;
