import React, {useState} from "react"
import { Button, makeStyles, AppBar as Bar, Toolbar, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Modal from "./Modal"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    marginRight: theme.spacing(1),
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.warning.main,
    color: "black",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  }
}))

function AppBar(props) {
  
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  
  function handleClose() {
    setOpen(false)
  }
  
  const modalDeleteActions = (
    <div>
      <Button autoFocus onClick={handleClose}
              variant="contained" className={classes.cancelButton}
      >
        Annuler
      </Button>
      <Button onClick={() => {
        handleClose()
        props.delete()
      }}
              variant="contained" className={classes.deleteButton}
      >
        Supprimer
      </Button>
    </div>
  )
  
  return (
    <Bar className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={props.close} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {props.title}
        </Typography>
        {props.delete && (
          <div>
            <IconButton className={classes.deleteButton} onClick={() => setOpen(true)}>
              <DeleteIcon />
            </IconButton>
            <Modal title={props.confirmDelete.title} handleClose={handleClose} actions={modalDeleteActions} open={open}>
              {props.confirmDelete.content}
            </Modal>
          </div>
        )}
        <Button autoFocus className={classes.saveButton} onClick={props.save}>
          Enregistrer
        </Button>
      </Toolbar>
    </Bar>
  )
}

export default AppBar