import React, { useState, useContext } from "react";
import { Button, TextField } from "@material-ui/core";
import { UserContext } from "../UserContext";
import useApi from "../hooks/useApi";
import logo from "../assets/logo_mono.jpg";
import useSnackbar from "../hooks/useSnackbar";

function Connexion() {
  const { login } = useContext(UserContext);
  const { addErrorSnackbar } = useSnackbar();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { securityApi } = useApi();

  const handleLogin = async () => {
    await securityApi
      .login(username, password)
      .then((data) => {
        if (data.user && data.expiresAt) {
          login(data.user, data.expiresAt);
          setTimeout(() => window.location.reload(), 500);
        } else {
          if (data.error) {
            addErrorSnackbar(data.error);
          }
        }
      })
      .catch((error) => {
        // DO NOTHING
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      <img src={logo} alt="Logo Cristal'In" style={{ width: "600px" }} />
      <h2>Gestion des analyses</h2>
      <TextField
        label="Nom d'utilisateur"
        value={username}
        onChange={(event) => setUsername(event.target?.value ?? "")}
      />
      <TextField
        label="Mot de passe"
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target?.value ?? "")}
      />
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
        onClick={handleLogin}
      >
        Connexion
      </Button>
    </div>
  );
}

export default Connexion;
