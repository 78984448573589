import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Dialog, Slide, Grid } from "@material-ui/core";
import AppBar from "../components/AppBar";
import SearchBox from "../components/SearchBox";
import useApi from "../hooks/useApi";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";
import useSnackbar from "../hooks/useSnackbar";
import { UserContext } from "../UserContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    "& > *": {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  twoCol: {
    "& > * > *:first-child": {
      marginRight: theme.spacing(2),
    },
  },
}));

const initAutoCompleteOrigineEau = [
  { label: "Ville" },
  { label: "Puit" },
  { label: "Forage" },
  { label: "Bas-Rhône" },
];

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

function EditAnalyse(props) {
  const { addSuccessSnackbar, addErrorSnackbar } = useSnackbar();

  const { analysesApi } = useApi();

  const { analyse, open, close } = props;

  const userContext = useContext(UserContext);
  const [clientSelected, setClientSelected] = useState({});
  const [autoCompleteTables, setAutoCompleteTables] = useState({
    autoCompleteOrigineEau: initAutoCompleteOrigineEau,
  });
  const [fieldsValues, setFieldsValues] = useState({
    client_id: null,
    date: moment(),
    stabilisant: "",
    desinfectant: "",
    dpd1: "",
    dpd3: "",
    chloramines: "",
    pH: "",
    tac: "",
    sel: "",
    phosphate: "",
    ageMediaFiltrant: "",
    ageEau: "",
    origineEau: "",
    horairesFiltration: "",
    conseils: "",
    createdAt: moment(),
    createdBy: "",
    createdByName: "",
    updatedAt: moment(),
    temperature: "",
  });

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    setFieldsValues((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    (async () => {
      setFieldsValues({
        client_id: analyse.client_id,
        date: analyse.date || moment(),
        stabilisant: analyse.stabilisant || "",
        desinfectant: analyse.desinfectant || "",
        dpd1: analyse.dpd1 || "",
        dpd3: analyse.dpd3 || "",
        chloramines: analyse.chloramines || "",
        pH: analyse.pH || "",
        tac: analyse.tac || "",
        sel: analyse.sel || "",
        phosphate: analyse.phosphate || "",
        ageMediaFiltrant: analyse.ageMediaFiltrant || "",
        ageEau: analyse.ageEau || "",
        origineEau: analyse.origineEau || "",
        horairesFiltration: analyse.horairesFiltration || "",
        conseils: analyse.conseils || "",
        createdAt: analyse.createdAt || moment(),
        createdBy: analyse.createdBy || userContext.getUser().user_id,
        createdByName: analyse.createdByName || "",
        updatedAt: analyse.updatedAt || moment(),
        temperature: analyse.temperature,
      });
      const tmpDbAutoCompleteTables = await analysesApi.listAutoComplete();
      const dbAutoCompleteTables = tmpDbAutoCompleteTables.error
        ? { autoCompleteOrigineEau: [] }
        : tmpDbAutoCompleteTables;
      const autoCompleteOrigineEau = removeDuplicates(
        dbAutoCompleteTables.autoCompleteOrigineEau.concat(
          initAutoCompleteOrigineEau
        ),
        "label"
      );
      setAutoCompleteTables({
        autoCompleteOrigineEau,
      });
    })();
  }, [analyse]);

  useEffect(() => {
    setFieldsValues((prev) => ({
      ...prev,
      client_id: clientSelected ? clientSelected.client_id : null,
    }));
  }, [clientSelected]);

  useEffect(() => {
    setClientSelected(
      props.clients.filter((item) => item.client_id === analyse.client_id)[0]
    );
  }, [props.clients, analyse]);

  const treatValues = (values) => {
    const data = values;
    const dateFields = ["date", "createdAt", "updatedAt"];
    for (let i in dateFields) {
      data[dateFields[i]] = (
        data[dateFields[i]] instanceof moment
          ? data[dateFields[i]]
          : moment(data[dateFields[i]].replace("T", " ").replace("Z", ""))
      ).format("YYYY-MM-DD HH:mm:ss");
    }
    return data;
  };

  const classes = useStyles();
  moment.locale("fr");

  const getString = (obj) => {
    return obj ? `${obj.fullname}` : "";
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={close}
        TransitionComponent={Transition}
      >
        <AppBar
          close={close}
          title={
            (clientSelected
              ? clientSelected.fullname
              : analyse.clientFullname || "Nom complet") +
            (analyse.chauffage ? " (Eau chauffée)" : "") +
            (analyse.couverte ? " (Piscine couverte)" : "") +
            " - " +
            moment(fieldsValues.date || analyse.date).format("D MMMM YYYY") +
            (analyse.createdByName
              ? " - " + analyse.createdByName
              : " - " + userContext.getUser().username)
          }
          save={async () => {
            let result;
            if (analyse.analyse_id) {
              result = await analysesApi.put(
                analyse.analyse_id,
                treatValues(fieldsValues)
              );
            } else {
              result = await analysesApi.post(treatValues(fieldsValues));
            }
            props.triggerUpdate();
            close();
            if (!result.errno && !result.error) {
              addSuccessSnackbar("Analyse enregistrée");
            } else {
              addErrorSnackbar(
                "Un problème est survenu, merci d'essayer à nouveau"
              );
            }
          }}
          delete={
            analyse.analyse_id &&
            (async () => {
              const result = await analysesApi.remove(analyse.analyse_id);
              props.triggerUpdate();
              close();
              if (!result.errno && !result.error) {
                addSuccessSnackbar("Analyse supprimée");
              } else {
                addErrorSnackbar(
                  "Un problème est survenu, merci d'essayer à nouveau"
                );
              }
            })
          }
          confirmDelete={
            analyse.analyse_id && {
              title: "Suppression d'une analyse",
              content:
                "Êtes-vous sûr de vouloir supprimer cette analyse ? Cette action est irréversible.",
            }
          }
        />
        <form noValidate autoComplete="off" className={classes.twoCol}>
          <Grid
            container
            style={{ display: "flex", flexDirection: "row", padding: "16px" }}
          >
            <Grid item style={{ flex: 1 }}>
              <Grid container className={classes.fieldContainer}>
                <Grid item>
                  <h3>Client</h3>
                  <SearchBox
                    disabled={Boolean(analyse.analyse_id)}
                    nomargin
                    data={props.clients}
                    label={"Client"}
                    value={clientSelected}
                    setValue={setClientSelected}
                    getString={getString}
                  />
                </Grid>
                <Grid item>
                  <h3>Date de l'analyse</h3>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale="fr"
                  >
                    <DatePicker
                      style={{ margin: "0" }}
                      autoOk
                      showTodayButton
                      id="date"
                      name="date"
                      label="Date de l'analyse"
                      format="D MMMM YYYY"
                      value={fieldsValues.date}
                      onChange={(value) =>
                        setFieldsValues((prev) => ({ ...prev, date: value }))
                      }
                      cancelLabel="Annuler"
                      todayLabel="Aujourd'hui"
                      okLabel="OK"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <h3>Stabilisant (Entre 25 et 50 mg/l)</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    id="stabilisant"
                    value={fieldsValues.stabilisant}
                    onChange={handleChange("stabilisant")}
                    name="stabilisant"
                    label="Stabilisant"
                  />
                </Grid>
              </Grid>
              <h3>
                Désinfectant (Entre 1 et 1.5 ppm) :{" "}
                {analyse.clientDesinfectant
                  ? analyse.clientDesinfectant
                  : clientSelected
                  ? clientSelected.desinfectant
                  : ""}
              </h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    id="desinfectant"
                    value={fieldsValues.desinfectant}
                    onChange={handleChange("desinfectant")}
                    name="desinfectant"
                    label="Désinfectant"
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.fieldContainer}>
                <Grid item>
                  <h3>Chlore libre (DPD1)</h3>
                  <TextField
                    id="dpd1"
                    value={fieldsValues.dpd1}
                    onChange={handleChange("dpd1")}
                    name="dpd1"
                    label="DPD1"
                  />
                </Grid>
                <Grid item>
                  <h3>Chlore libre (DPD3)</h3>
                  <TextField
                    id="dpd3"
                    value={fieldsValues.dpd3}
                    onChange={handleChange("dpd3")}
                    name="dpd3"
                    label="DPD2"
                  />
                </Grid>
                <Grid item>
                  <h3>Chloramines</h3>
                  <TextField
                    id="chloramines"
                    value={fieldsValues.chloramines}
                    onChange={handleChange("chloramines")}
                    name="chloramines"
                    label="Chloramines"
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.fieldContainer}>
                <Grid item>
                  <h3>Température de l'eau</h3>
                  <TextField
                    id="temperature"
                    value={fieldsValues.temperature}
                    onChange={handleChange("temperature")}
                    name="temperature"
                    label="Température de l'eau"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Grid container className={classes.fieldContainer}>
                <Grid item>
                  <h3>pH (7.2)</h3>
                  <TextField
                    id="pH"
                    value={fieldsValues.pH}
                    onChange={handleChange("pH")}
                    name="pH"
                    label="pH"
                  />
                </Grid>
                <Grid item>
                  <h3>TAC (Entre 8 et 12° F)</h3>
                  <TextField
                    id="tac"
                    value={fieldsValues.tac}
                    onChange={handleChange("tac")}
                    name="tac"
                    label="TAC"
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.fieldContainer}>
                <Grid item>
                  <h3>
                    Sel (
                    {analyse.clientTauxSelRequis
                      ? analyse.clientTauxSelRequis
                      : clientSelected
                      ? clientSelected.tauxSelRequis
                      : ""}
                    )
                  </h3>
                  <TextField
                    id="sel"
                    value={fieldsValues.sel}
                    onChange={handleChange("sel")}
                    name="sel"
                    label="Sel"
                  />
                </Grid>
                <Grid item>
                  <h3>Phosphates (0)</h3>
                  <TextField
                    id="phosphate"
                    value={fieldsValues.phosphate}
                    onChange={handleChange("phosphate")}
                    name="phosphate"
                    label="Phosphates"
                  />
                </Grid>
              </Grid>
              <h3>Âge du média filtrant</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    id="ageMediaFiltrant"
                    value={fieldsValues.ageMediaFiltrant}
                    onChange={handleChange("ageMediaFiltrant")}
                    name="ageMediaFiltrant"
                    label="Age du média filtrant"
                  />
                </Grid>
              </Grid>
              <h3>Âge de l'eau</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    id="ageEau"
                    value={fieldsValues.ageEau}
                    onChange={handleChange("ageEau")}
                    name="ageEau"
                    label="Age de l'eau"
                  />
                </Grid>
              </Grid>
              <h3>Origine de l'eau</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    id="search-box-origineEau"
                    freeSolo
                    getOptionLabel={(option) =>
                      option.label ? option.label : ""
                    }
                    options={autoCompleteTables.autoCompleteOrigineEau}
                    style={{ width: "100%" }}
                    value={{ label: fieldsValues.origineEau }}
                    inputValue={fieldsValues.origineEau}
                    onInputChange={(event, newInput) => {
                      if (newInput !== fieldsValues.origineEau) {
                        setFieldsValues((prev) => ({
                          ...prev,
                          origineEau: newInput,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="origineEau"
                        label="Origine de l'eau"
                        name="origineEau"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <h3>Horaires de filtration</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    id="horairesFiltration"
                    value={fieldsValues.horairesFiltration}
                    onChange={handleChange("horairesFiltration")}
                    name="horairesFiltration"
                    label="Horaires de filtration"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ padding: "16px" }}>
            <Grid item style={{ flex: 1 }}>
              <h3>Commentaires/Conseils donnés au client</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    id="conseils"
                    value={fieldsValues.conseils}
                    multiline
                    onChange={handleChange("conseils")}
                    name="conseils"
                    label="Conseils"
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </div>
  );
}

export default EditAnalyse;
