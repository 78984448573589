function useSnackbar() {
  
  const getConfig = (message, variant, duration = 6000) => {
    return {
      message,
      variant,
      duration,
    }
  }
  
  const addSuccessSnackbar = (message, duration) => {
    const event = new CustomEvent("show-toast-message", {detail: getConfig(message, "success", duration)})
    document.dispatchEvent(event)
  }
  
  const addErrorSnackbar = (message, duration) => {
    const event = new CustomEvent("show-toast-message", {detail: getConfig(message, "error", duration)})
    document.dispatchEvent(event)
  }
  
  return {addSuccessSnackbar, addErrorSnackbar}
  
}

export default useSnackbar