import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Dialog, Slide, Grid } from "@material-ui/core";
import AppBar from "../components/AppBar";
import AnalyseDataTable from "../Analyse/AnalyseDatatable";
import useApi from "../hooks/useApi";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";
import useSnackbar from "../hooks/useSnackbar";
import { UserContext } from "../UserContext";
import { Checkbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    "& > *": {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  twoCol: {
    "& > * > *:first-child": {
      marginRight: theme.spacing(2),
    },
  },
}));

const initAutoCompleteTraitement = [
  { label: "Chlore" },
  { label: "Brome" },
  { label: "Oxygène actif" },
  { label: "Autre" },
  { label: "Pompe rédox" },
  { label: "Pompe pH" },
  { label: "Rédox" },
  { label: "Electrolyse au sel" },
  { label: "Pompe injection oxygène actif" },
];

const initAutoCompletePiscine = [
  { label: "Carrelage" },
  { label: "Coque" },
  { label: "Liner" },
  { label: "Enduit" },
];

const initAutoCompleteMarque = [];

const initAutoCompleteFiltre = [
  { label: "Cartouche" },
  { label: "Sable" },
  { label: "Chaussette" },
];

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

function EditClient(props) {
  const { addSuccessSnackbar, addErrorSnackbar } = useSnackbar();

  const userContext = useContext(UserContext);
  const [autoCompleteTables, setAutoCompleteTables] = useState({
    autoCompleteTraitement: initAutoCompleteTraitement,
    autoCompletePiscine: initAutoCompletePiscine,
    autoCompleteMarque: initAutoCompleteMarque,
    autoCompleteFiltre: initAutoCompleteFiltre,
  });
  const [analyseFiche, setAnalyseFiche] = useState({});
  const [openAnalyse, setOpenAnalyse] = useState(false);
  const [analyses, setAnalyses] = useState([]);
  const [fieldsValues, setFieldsValues] = useState({
    fullname: "",
    email: "",
    address: "",
    phone: "",
    piscineType: "",
    piscineVolume: "",
    filtreType: "",
    filtreVolume: "",
    pompePuissance: "",
    pompeMarque: "",
    pompeDebit: "",
    traitementEau: "",
    traitementDateRevision: null,
    pH: "",
    pHMarque: "",
    desinfectant: "",
    desinfectantMarque: "",
    tauxSelRequis: "",
    createdAt: moment(),
    createdBy: "",
    updatedAt: moment(),
    chauffage: false,
    couverte: false,
  });

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    setFieldsValues((prev) => ({ ...prev, [name]: value }));
  };

  const { clientsApi, analysesApi } = useApi();

  const { client, clients, open, close } = props;

  useEffect(() => {
    (async () => {
      const tmpAnalyses = await analysesApi.getFromClientId(client.client_id);
      if (!tmpAnalyses.error) {
        setAnalyses(tmpAnalyses);
      } else {
        setAnalyses([]);
      }
      setFieldsValues({
        fullname: client.fullname || "",
        email: client.email || "",
        address: client.address || "",
        phone: client.phone || "",
        piscineType: client.piscineType || "",
        piscineVolume: client.piscineVolume || "",
        filtreType: client.filtreType || "",
        filtreVolume: client.filtreVolume || "",
        pompePuissance: client.pompePuissance || "",
        pompeMarque: client.pompeMarque || "",
        pompeDebit: client.pompeDebit || "",
        traitementEau: client.traitementEau || "",
        traitementDateRevision: client.traitementDateRevision || null,
        pH: client.pH || "",
        pHMarque: client.pHMarque || "",
        desinfectant: client.desinfectant || "",
        desinfectantMarque: client.desinfectantMarque || "",
        tauxSelRequis: client.tauxSelRequis || "",
        createdAt: client.createdAt || moment(),
        createdBy: client.createdBy || userContext.getUser().user_id,
        updatedAt: client.updatedAt || moment(),
        chauffage: client.chauffage || false,
        couverte: client.couverte || false,
      });
      const tmpDbAutoCompleteTables = await clientsApi.listAutoComplete();
      const dbAutoCompleteTables = tmpDbAutoCompleteTables.error
        ? {
            autoCompleteTraitement: [],
            autoCompletePiscine: [],
            autoCompleteMarque: [],
            autoCompleteFiltre: [],
          }
        : tmpDbAutoCompleteTables;
      const autoCompleteTraitement = removeDuplicates(
        dbAutoCompleteTables.autoCompleteTraitement.concat(
          initAutoCompleteTraitement
        ),
        "label"
      );
      const autoCompletePiscine = removeDuplicates(
        dbAutoCompleteTables.autoCompletePiscine.concat(
          initAutoCompletePiscine
        ),
        "label"
      );
      const autoCompleteMarque = removeDuplicates(
        dbAutoCompleteTables.autoCompleteMarque.concat(initAutoCompleteMarque),
        "label"
      );
      const autoCompleteFiltre = removeDuplicates(
        dbAutoCompleteTables.autoCompleteFiltre.concat(initAutoCompleteFiltre),
        "label"
      );
      setAutoCompleteTables({
        autoCompleteTraitement,
        autoCompletePiscine,
        autoCompleteMarque,
        autoCompleteFiltre,
      });
    })();
  }, [client]);

  const treatValues = (values) => {
    const data = values;
    const intFields = ["piscineVolume", "filtreVolume", "pompeDebit"];
    const dateFields = ["traitementDateRevision", "createdAt", "updatedAt"];
    for (let i in intFields) {
      if (data[intFields[i]] === "") {
        data[intFields[i]] = null;
      }
    }
    for (let i in dateFields) {
      data[dateFields[i]] = data[dateFields[i]]
        ? (data[dateFields[i]] instanceof moment
            ? data[dateFields[i]]
            : moment(data[dateFields[i]].replace("T", " ").replace("Z", ""))
          ).format("YYYY-MM-DD HH:mm:ss")
        : null;
    }
    return data;
  };

  const classes = useStyles();
  moment.locale("fr");

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={close}
        TransitionComponent={Transition}
      >
        <AppBar
          close={close}
          title={
            (fieldsValues.fullname || client.fullname || "Nom complet") +
            " - " +
            (client.countAnalyses || 0) +
            " analyse(s)"
          }
          save={async () => {
            let result;
            if (client.client_id) {
              result = await clientsApi.put(
                client.client_id,
                treatValues(fieldsValues)
              );
            } else {
              result = await clientsApi.post(treatValues(fieldsValues));
            }
            props.triggerUpdate();
            if (!result.errno && !result.error) {
              addSuccessSnackbar("Client enregistré");
            } else {
              addErrorSnackbar(
                "Un problème est survenu, merci d'essayer à nouveau"
              );
            }
          }}
          delete={
            client.client_id &&
            (async () => {
              const result = await clientsApi.remove(client.client_id);
              props.triggerUpdate();
              close();
              if (!result.errno && !result.error) {
                addSuccessSnackbar("Client supprimé");
              } else {
                addErrorSnackbar(
                  "Un problème est survenu, merci d'essayer à nouveau"
                );
              }
            })
          }
          confirmDelete={
            client.client_id && {
              title: "Suppression d'un client",
              content:
                "Êtes-vous sûr de vouloir supprimer ce client ? Cette action est irréversible.",
            }
          }
        />
        <form noValidate autoComplete="off" className={classes.twoCol}>
          <Grid
            container
            style={{ display: "flex", flexDirection: "row", padding: "16px" }}
          >
            <Grid item style={{ flex: 1 }}>
              <h3>Nom et coordonnées client</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item>
                  <TextField
                    id="fullname"
                    value={fieldsValues.fullname}
                    onChange={handleChange("fullname")}
                    name="fullname"
                    label="Nom complet"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="email"
                    value={fieldsValues.email}
                    onChange={handleChange("email")}
                    name="email"
                    label="Email"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="address"
                    value={fieldsValues.address}
                    onChange={handleChange("address")}
                    name="address"
                    label="Adresse"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="phone"
                    value={fieldsValues.phone}
                    onChange={handleChange("phone")}
                    name="phone"
                    label="Téléphone"
                  />
                </Grid>
              </Grid>
              <h3>Piscine</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    id="search-box-piscineType"
                    freeSolo
                    getOptionLabel={(option) =>
                      option.label ? option.label : ""
                    }
                    options={autoCompleteTables.autoCompletePiscine}
                    style={{ width: "100%" }}
                    inputValue={fieldsValues.piscineType}
                    onInputChange={(event, newInput) => {
                      if (newInput !== fieldsValues.piscineType) {
                        setFieldsValues((prev) => ({
                          ...prev,
                          piscineType: newInput,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="piscineType"
                        label="Type"
                        name="piscineType"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    id="piscineVolume"
                    value={fieldsValues.piscineVolume || ""}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (/^\d+$/.test(value) || value === "") {
                        handleChange("piscineVolume")(event);
                      }
                    }}
                    name="piscineVolume"
                    label="Volume"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m3</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      min: "0",
                    }}
                  />
                </Grid>
              </Grid>
              <h3>Filtration</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    id="search-box-filtreType"
                    freeSolo
                    getOptionLabel={(option) =>
                      option.label ? option.label : ""
                    }
                    options={autoCompleteTables.autoCompleteFiltre}
                    style={{ width: "100%" }}
                    inputValue={fieldsValues.filtreType}
                    onInputChange={(event, newInput) => {
                      if (newInput !== fieldsValues.filtreType) {
                        setFieldsValues((prev) => ({
                          ...prev,
                          filtreType: newInput,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="filtreType"
                        label="Type de filtre"
                        name="filtreType"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="filtreVolume"
                    value={fieldsValues.filtreVolume || ""}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (/^\d+$/.test(value) || value === "") {
                        handleChange("filtreVolume")(event);
                      }
                    }}
                    name="filtreVolume"
                    label="Volume du filtre"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m3/h</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      min: "0",
                    }}
                  />
                </Grid>
              </Grid>
              <h3>Pompe</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item>
                  <TextField
                    id="pompePuissance"
                    value={fieldsValues.pompePuissance}
                    onChange={handleChange("pompePuissance")}
                    name="pompePuissance"
                    label="Puissance"
                  />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    id="search-box-pompeMarque"
                    freeSolo
                    getOptionLabel={(option) =>
                      option.label ? option.label : ""
                    }
                    options={autoCompleteTables.autoCompleteMarque}
                    style={{ width: "100%" }}
                    inputValue={fieldsValues.pompeMarque}
                    onInputChange={(event, newInput) => {
                      if (newInput !== fieldsValues.pompeMarque) {
                        setFieldsValues((prev) => ({
                          ...prev,
                          pompeMarque: newInput,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="pompeMarque"
                        label="Marque"
                        name="pompeMarque"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="pompeDebit"
                    value={fieldsValues.pompeDebit || ""}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (/^\d+$/.test(value) || value === "") {
                        handleChange("pompeDebit")(event);
                      }
                    }}
                    name="pompeDebit"
                    label="Débit"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m3/h</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      min: "0",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <h3>Traitement eau</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    id="search-box-traitement"
                    freeSolo
                    getOptionLabel={(option) =>
                      option.label ? option.label : ""
                    }
                    options={autoCompleteTables.autoCompleteTraitement}
                    style={{ width: "100%" }}
                    inputValue={fieldsValues.traitementEau}
                    onInputChange={(event, newInput) => {
                      if (newInput !== fieldsValues.traitementEau) {
                        setFieldsValues((prev) => ({
                          ...prev,
                          traitementEau: newInput,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="traitementEau"
                        label="Manuel/Auto/Domotique"
                        name="traitementEau"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  {/*<TextField
                    id="traitementDateRevision"
                    value={fieldsValues.traitementDateRevision}
                    onChange={handleChange("traitementDateRevision")}
                    name="traitementDateRevision"
                    label="Date de révision"
                  />*/}
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale="fr"
                  >
                    <KeyboardDatePicker
                      style={{ margin: "0" }}
                      autoOk
                      clearable
                      showTodayButton
                      id="traitementDateRevision"
                      name="traitementDateRevision"
                      label="Date de révision"
                      format="DD/MM/YYYY"
                      value={fieldsValues.traitementDateRevision}
                      onChange={(value) =>
                        setFieldsValues((prev) => ({
                          ...prev,
                          traitementDateRevision: value,
                        }))
                      }
                      cancelLabel="Annuler"
                      todayLabel="Aujourd'hui"
                      okLabel="OK"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <h3>pH</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    id="search-box-pH"
                    freeSolo
                    getOptionLabel={(option) =>
                      option.label ? option.label : ""
                    }
                    options={autoCompleteTables.autoCompleteTraitement}
                    style={{ width: "100%" }}
                    inputValue={fieldsValues.pH}
                    onInputChange={(event, newInput) => {
                      if (newInput !== fieldsValues.pH) {
                        setFieldsValues((prev) => ({ ...prev, pH: newInput }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="pH"
                        label="Manuel/Pompe pH"
                        name="pH"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    id="search-box-pHMarque"
                    freeSolo
                    getOptionLabel={(option) =>
                      option.label ? option.label : ""
                    }
                    options={autoCompleteTables.autoCompleteMarque}
                    style={{ width: "100%" }}
                    inputValue={fieldsValues.pHMarque}
                    onInputChange={(event, newInput) => {
                      if (newInput !== fieldsValues.pHMarque) {
                        setFieldsValues((prev) => ({
                          ...prev,
                          pHMarque: newInput,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="pHMarque"
                        label="Marque"
                        name="pHMarque"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <h3>Désinfectant</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    id="search-box-desinfectant"
                    freeSolo
                    getOptionLabel={(option) =>
                      option.label ? option.label : ""
                    }
                    options={autoCompleteTables.autoCompleteTraitement}
                    style={{ width: "100%" }}
                    inputValue={fieldsValues.desinfectant}
                    onInputChange={(event, newInput) => {
                      if (newInput !== fieldsValues.desinfectant) {
                        setFieldsValues((prev) => ({
                          ...prev,
                          desinfectant: newInput,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="desinfectant"
                        label="Manuel/Automatique"
                        name="desinfectant"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    id="search-box-desinfectantMarque"
                    freeSolo
                    getOptionLabel={(option) =>
                      option.label ? option.label : ""
                    }
                    options={autoCompleteTables.autoCompleteMarque}
                    style={{ width: "100%" }}
                    inputValue={fieldsValues.desinfectantMarque}
                    onInputChange={(event, newInput) => {
                      if (newInput !== fieldsValues.desinfectantMarque) {
                        setFieldsValues((prev) => ({
                          ...prev,
                          desinfectantMarque: newInput,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="desinfectantMarque"
                        label="Marque"
                        name="desinfectantMarque"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <h3>Taux de sel requis</h3>
              <Grid container className={classes.fieldContainer}>
                <Grid item>
                  <TextField
                    id="tauxSelRequis"
                    value={fieldsValues.tauxSelRequis}
                    onChange={handleChange("tauxSelRequis")}
                    name="tauxSelRequis"
                    label="Taux"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        value={fieldsValues.chauffage}
                        checked={Boolean(fieldsValues.chauffage)}
                        onChange={(event) => {
                          setFieldsValues((prev) => ({
                            ...prev,
                            chauffage: event.target.checked,
                          }));
                        }}
                      />
                    }
                    label="Eau chauffée"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        value={fieldsValues.couverte}
                        checked={Boolean(fieldsValues.couverte)}
                        onChange={(event) => {
                          setFieldsValues((prev) => ({
                            ...prev,
                            couverte: event.target.checked,
                          }));
                        }}
                      />
                    }
                    label="Piscine couverte"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <div style={{ padding: "16px" }}>
          <Button
            style={{ marginBottom: "16px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              setAnalyseFiche({
                client_id: client.client_id,
                clientFullname: client.fullname,
              });
              setOpenAnalyse(true);
            }}
          >
            Nouvelle analyse
          </Button>
          <AnalyseDataTable
            open={openAnalyse}
            clients={clients}
            setOpen={setOpenAnalyse}
            analyseFiche={analyseFiche}
            setAnalyseFiche={setAnalyseFiche}
            analyses={analyses}
            triggerUpdate={async () => {
              const tmpAnalyses = await analysesApi.getFromClientId(
                client.client_id
              );
              if (!tmpAnalyses.error) {
                setAnalyses(tmpAnalyses);
              } else {
                setAnalyses([]);
              }
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}

export default EditClient;
