import React from "react"
import MUIDataTable from 'mui-datatables'

function DataTable(props) {
  
  const options = {
    filterType: 'multiselect',
    onRowClick: props.handleRowClick,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: "Désolé, aucun résultat",
        toolTip: "Trier",
        columnHeaderTooltip: column => `Trier par ${column.label}`
      },
      pagination: {
        next: "Page suivante",
        previous: "Page précédente",
        rowsPerPage: "Lignes par page :",
        displayRows: "sur",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Exporter en CSV",
        print: "Imprimer",
        viewColumns: "Sélection des colonnes",
        filterTable: "Filtres",
      },
      filter: {
        all: "Tous",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      viewColumns: {
        title: "Colonnes affichées",
        titleAria: "Afficher/Cacher colonnes",
      },
      selectedRows: {
        text: "ligne(s) sélectionnée(s)",
        delete: "Supprimer",
        deleteAria: "Supprimer les lignes sélectionnées",
      },
    },
  }
  
  return (
    <>
      <MUIDataTable
        style={{width: "100%"}}
        title={props.title}
        data={props.data}
        columns={props.columns}
        options={options}
      />
    </>
  )
}

export default DataTable