import moment from "moment";
import React from "react";
import DataTable from "../components/DataTable";
import EditAnalyse from "./EditAnalyse";

function AnalyseDataTable(props) {
  const { analyses } = props;

  const handleClick = (event, indexes) => {
    // filter : analyses.filter((item) => item.analyse_id === id)
    // open the details page with editing functionality
    props.setAnalyseFiche(analyses[indexes.dataIndex]);
    props.setOpen(true);
  };

  const headers = [
    {
      name: "clientFullname",
      label: "Client",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "clientDesinfectant",
      label: "Désinfectant utilisé",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
        customBodyRender: (value) => {
          return value ? moment(value).format("DD/MM/YYYY") : "";
        },
      },
    },
    {
      name: "conseils",
      label: "Conseils donnés",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "stabilisant",
      label: "Stabilisant",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "desinfectant",
      label: "Désinfactant",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "dpd1",
      label: "Chlore libre (DPD1)",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "dpd3",
      label: "Chlore combiné (DPD3)",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "chloramines",
      label: "Chloramines",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "pH",
      label: "pH",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "tac",
      label: "TAC",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "sel",
      label: "Sel",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "phosphate",
      label: "Phosphates",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "ageMediaFiltrant",
      label: "Age du média filtrant",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "ageEau",
      label: "Age de l'eau",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "origineEau",
      label: "Origine de l'eau",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "horairesFiltration",
      label: "Horaires de filtration",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
    {
      name: "temperature",
      label: "Température de l'eau",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: (columnMeta) => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
      },
    },
  ];

  return (
    <>
      <DataTable
        title={"Liste des analyses"}
        columns={headers}
        data={analyses}
        dataKey={"analyse_id"}
        handleRowClick={handleClick}
      />
      <EditAnalyse
        open={props.open}
        close={() => props.setOpen(false)}
        triggerUpdate={props.triggerUpdate}
        analyse={props.analyseFiche}
        clients={props.clients}
      />
    </>
  );
}

export default AnalyseDataTable;
