function useApi() {
  function serializeQueryString(obj) {
    const str = [];
    for (let p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }

  const apiURL = process.env.REACT_APP_API_URL;

  const usersEndpoint = "/users";

  const clientsEndpoint = "/clients";

  const analysesEndpoint = "/analyses";

  const loginEndpoint = "/signin";
  const logoutEndpoint = "/logout";
  const meEndpoint = "/me";

  const getAll = async (endpoint, queryParams = []) => {
    return await fetch(
      apiURL + endpoint + "?" + serializeQueryString(queryParams),
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .catch((error) => ({
        error,
      }));
  };

  const get = async (endpoint, id) => {
    return await fetch(apiURL + endpoint + "/" + id, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .catch((error) => ({
        error,
      }));
  };

  const post = async (endpoint, data) => {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    return await fetch(apiURL + endpoint, {
      method: "POST",
      body: JSON.stringify(data),
      headers: headers,
      credentials: "include",
    })
      .then((response) => response.json())
      .catch((error) => ({
        error,
      }));
  };

  const put = async (endpoint, id, data) => {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    return await fetch(apiURL + endpoint + "/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: headers,
      credentials: "include",
    })
      .then((response) => response.json())
      .catch((error) => ({
        error,
      }));
  };

  const remove = async (endpoint, id) => {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    return await fetch(apiURL + endpoint + "/" + id, {
      method: "DELETE",
      headers: headers,
      credentials: "include",
    })
      .then((response) => response.json())
      .catch((error) => ({
        error,
      }));
  };

  const securityApi = {
    login: async (username, password) =>
      await post(loginEndpoint, { username, password }),
    logout: async () => await post(logoutEndpoint),
    me: async () => await post(meEndpoint),
  };

  const usersApi = {
    getAll: async () => await getAll(usersEndpoint),
    get: async (userId) => await get(usersEndpoint, userId),
    post: async (data) => await post(usersEndpoint, data),
    put: async (userId, data) => await put(usersEndpoint, userId, data),
  };

  const clientsApi = {
    listAutoComplete: async () =>
      await getAll(clientsEndpoint + "/autocomplete"),
    getAll: async () => await getAll(clientsEndpoint),
    get: async (clientId) => await get(clientsEndpoint, clientId),
    post: async (data) => await post(clientsEndpoint, data),
    put: async (clientId, data) => await put(clientsEndpoint, clientId, data),
    remove: async (clientId) => await remove(clientsEndpoint, clientId),
  };

  const analysesApi = {
    listAutoComplete: async () =>
      await getAll(analysesEndpoint + "/autocomplete"),
    getAll: async () => await getAll(analysesEndpoint),
    getFromClientId: async (clientId) =>
      await getAll(analysesEndpoint, { client_id: clientId }),
    get: async (analyseId) => await get(analysesEndpoint, analyseId),
    post: async (data) => await post(analysesEndpoint, data),
    put: async (analyseId, data) =>
      await put(analysesEndpoint, analyseId, data),
    remove: async (analyseId) => await remove(analysesEndpoint, analyseId),
  };

  return { usersApi, clientsApi, analysesApi, securityApi };
}

export default useApi;
