import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import SearchBox from "../components/SearchBox";
import useApi from "../hooks/useApi";
import AnalyseDataTable from "./AnalyseDatatable";

function Analyse(props) {
  const [analyses, setAnalyses] = useState([]);
  const [cpyAnalyses, setCpyAnalyses] = useState([]);
  const [clients, setClients] = useState([]);

  const { analysesApi, clientsApi } = useApi();

  useEffect(() => {
    (async () => {
      const tmpAnalyses = await analysesApi.getAll();
      if (!tmpAnalyses.error) {
        setAnalyses(tmpAnalyses);
        setCpyAnalyses(tmpAnalyses);
      } else {
        setAnalyses([]);
        setCpyAnalyses([]);
      }
      const tmpClients = await clientsApi.getAll();
      if (!tmpClients.error) {
        setClients(tmpClients);
      } else {
        setClients([]);
      }
    })();
  }, []);

  const [clientSelected, setClientSelected] = useState(false);

  useEffect(() => {
    if (clientSelected) {
      setAnalyses(
        cpyAnalyses.filter(
          (item) => item.client_id === clientSelected.client_id
        )
      );
    } else {
      setAnalyses(cpyAnalyses);
    }
  }, [clientSelected, cpyAnalyses]);

  const getString = (obj) => {
    return obj ? `${obj.fullname}` : "";
  };

  const triggerUpdate = async () => {
    const tmpAnalyses = await analysesApi.getAll();
    if (!tmpAnalyses.error) {
      setAnalyses(tmpAnalyses);
      setCpyAnalyses(tmpAnalyses);
    } else {
      setAnalyses([]);
      setCpyAnalyses([]);
    }
  };
  const [analyseFiche, setAnalyseFiche] = useState({});
  const [open, setOpen] = useState(false);

  return (
    <div>
      <h1>Analyses</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <SearchBox
          nomargin
          data={clients}
          label={"Client"}
          value={clientSelected}
          setValue={setClientSelected}
          getString={getString}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setAnalyseFiche({});
            setOpen(true);
          }}
        >
          Nouvelle analyse
        </Button>
      </div>
      <AnalyseDataTable
        open={open}
        setOpen={setOpen}
        analyseFiche={analyseFiche}
        setAnalyseFiche={setAnalyseFiche}
        clients={clients}
        analyses={analyses}
        triggerUpdate={triggerUpdate}
      />
    </div>
  );
}

export default Analyse;
